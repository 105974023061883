import base from './base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const sign = {

	signAll(params) {
		return axios.get(`${base.sq}/SchedulingSign/signAll`, {params:params});
	},
	signEdit(params) {
		return axios.post(`${base.sq}/SchedulingSign/signEdit`, qs.stringify(params));
	},
	schedulingListAll(params) {
		return axios.get(`${base.sq}/scheduling/schedulingListAll`, {
			params,params
		});
	},
	schedulingImport(params) {
		return axios.post(`${base.sq}/Scheduling/schedulingImport`,params);
	},
}

export default sign;
